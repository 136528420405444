<template>
  <div>
      <div class="card">
          <div class="card-header">
                <div class="card-title m-0">
                    <h3>Ücretlendirme</h3>
                </div>
          </div>
          <div class="card-body">
            <div class="row mb-6">                  
                <label class="col-lg-3 col-form-label fw-bold fs-6">Görüşme Ücreti</label>
                <div class="col-lg-2">
                    <input type="number" class="form-control col-lg-6 form-control-lg form-control-solid" step=".01" name="" v-model="pricing.price">
                </div>
            </div>

            <div class="row mb-6">                  
                <label class="col-lg-3 col-form-label fw-bold fs-6">İndirim Tutarı</label>
                <div class="col-lg-2">
                    <input type="number" class="form-control col-lg-6 form-control-lg form-control-solid"  name="" v-model="pricing.discount">
                </div>
            </div>


           
          </div>

          
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="save()" class="btn btn-primary">
            <span class="indicator-label"> Kaydet </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        
    </div>
              
    
  </div>
</template>


  <script>
    import api from '@/core/services/ApiService'
    import Swal from "sweetalert2/dist/sweetalert2.js";

    export default {
        name:'Pricing',
        props:['profile'],
        
        data(){
            return {
                pricingSchema:{
                    price:null,
                    discount:null,
                    EarnScore:0,
                    consultantId:this.profile.userId
                },
                pricing:{
                    price:null,
                    discount:null,
                    EarnScore:0,
                    consultantId:this.profile.userId
                }
            }
        },
        methods:{
            save(){                
                api.post('/User/SetDoctorPrice',this.pricing).then((res)=>{
                    Swal.fire({
                        title:'Ücretlendirme',
                        text:'Danışman Ücretlendirme bilgisi güncellendi',
                        icon:'success',
                    }).then(()=>{
                        window.location.reload();
                    })
                })
            }
        },
        mounted(){
            
            api.get('/User/GetDoctorPrice/'+this.profile.userId).then((res)=>{
                this.pricing = res.data || this.pricingSchema;
                this.pricing.EarnScore = 0;
                
            })

        }

    }
      </script>

